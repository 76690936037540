import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
    <Layout>
        <SEO title="Contato"/>


        <Link to="/">Inicio</Link>
    </Layout>
)

export default ContactPage
